import React, { Component } from 'react';
import './FrBodySideBar.css';

class HFrBodySideBar extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div class="col-lg-3 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px">
                <div class="shop-sidebar-wrap">
                    <div class="sidebar-widget mb-30px bg-white">
                        <h3 class="sidebar-title">CATEGORIES</h3>
                        <div class="accordion" id="accordionExample">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <a href="#" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed">Accessories (7)</a>
                                </div>

                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Dresses</a></li>
                                            <li><a href="#">Jackets &amp; Coats</a></li>
                                            <li><a href="#">Sweaters</a></li>
                                            <li><a href="#">Jeans</a></li>
                                            <li><a href="#">Blouses &amp; Shirts</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Body Parts (20)</a>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Handbag (10)</a></li>
                                            <li><a href="#">Accessories (7)</a></li>
                                            <li><a href="#">Clothing (8)</a></li>
                                            <li><a href="#">Shoes (3)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Brake Parts (16)</a>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Brake Tools (6)</a></li>
                                            <li><a href="#">Drive shafts (3)</a></li>
                                            <li><a href="#">Emergency Brake (3)</a></li>
                                            <li><a href="#">Spools (0)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Car Seats (0)</a>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Booster (0)</a></li>
                                            <li><a href="#">Convertible (0)</a></li>
                                            <li><a href="#">Infant (0)</a></li>
                                            <li><a href="#">Toddler (0)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFive">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Engine Parts</a>
                                </div>
                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Calculators (0)</a></li>
                                            <li><a href="#">Check Trousers (0)</a></li>
                                            <li><a href="#">Ink & Toner (0)</a></li>
                                            <li><a href="#">Low-Cut Jeans (0)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSix">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">Indoor Living</a>
                                </div>
                                <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Bridge (0)</a></li>
                                            <li><a href="#">Hub (0)</a></li>
                                            <li><a href="#">Repeater (0)</a></li>
                                            <li><a href="#">Switch (0)</a></li>
                                            <li><a href="#">Video Games Cosoles (0)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingSeven">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">Lighting</a>
                                </div>
                                <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Bulbs (0)</a></li>
                                            <li><a href="#">Headlights (0)</a></li>
                                            <li><a href="#">Light Bars (0)</a></li>
                                            <li><a href="#">Light Kits (0)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingEight">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">Moto Oil</a>
                                </div>
                                <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">2-Stroke (0)</a></li>
                                            <li><a href="#">4-Stroke (0)</a></li>
                                            <li><a href="#">Diesel (0)</a></li>
                                            <li><a href="#">Gasoline (0)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingNine">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">Repair Parts</a>
                                </div>
                                <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Bearings (0)</a></li>
                                            <li><a href="#">Rim Screws (0)</a></li>
                                            <li><a href="#">Seals & Hubs (0)</a></li>
                                            <li><a href="#">Simulators (0)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTen">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">Shop</a>
                                </div>
                                <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Saws (0)</a></li>
                                            <li><a href="#">Concrete Tools (8)</a></li>
                                            <li><a href="#">Drills (2)</a></li>
                                            <li><a href="#">Sanders (1)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingEleven">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">Turbo Systems</a>
                                </div>
                                <div id="collapseEleven" class="collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Turbo Blanket (4)</a></li>
                                            <li><a href="#">Turbo Kits (4)</a></li>
                                            <li><a href="#">Turbo Wrap (4)</a></li>
                                            <li><a href="#">Turbocharger (0)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingTwelve">
                                    <a href="#" class="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">Wheels &amp; Tires</a>
                                </div>
                                <div id="collapseTwelve" class="collapse" aria-labelledby="headingTwelve" data-bs-parent="#accordionExample">
                                    <div class="card-body">
                                        <ul class="category-list">
                                            <li><a href="#">Wheel Simulators (3)</a></li>
                                            <li><a href="#">Seals & Hubs (10)</a></li>
                                            <li><a href="#">Wheel Rim Screws (11)</a></li>
                                            <li><a href="#">Wheel Bearings (9)</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sidebar-widget-group bg-white mt-20">
                        <div class="sidebar-widget mt-20">
                            <h3 class="sidebar-title">FILLTER BY PRICE</h3>
                            <div class="price-filter">
                                <div id="slider-range"></div>
                                <div class="price-slider-amount">
                                    <input type="text" id="amount" name="price" placeholder="Add Your Price" />
                                </div>
                            </div>
                        </div>

                        <div class="sidebar-widget mt-20">
                            <h3 class="sidebar-title">MANUFACTURER</h3>
                            <div class="sidebar-widget-list">
                                <ul>
                                    <li>
                                        <div class="sidebar-widget-list-left">
                                            <input type="checkbox" /> <a href="#">Christian Dior<span>(6)</span> </a>
                                            <span class="checkmark"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="sidebar-widget-list-left">
                                            <input type="checkbox" value="" /> <a href="#">Diesel<span>(10)</span></a>
                                            <span class="checkmark"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="sidebar-widget-list-left">
                                            <input type="checkbox" value="" /> <a href="#">ferragamo<span>(13)</span> </a>
                                            <span class="checkmark"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="sidebar-widget-list-left">
                                            <input type="checkbox" value="" /> <a href="#">hermes<span>(17)</span> </a>
                                            <span class="checkmark"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="sidebar-widget-list-left">
                                            <input type="checkbox" value="" /> <a href="#">louis vuitton<span>(16)</span> </a>
                                            <span class="checkmark"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="sidebar-widget-list-left">
                                            <input type="checkbox" value="" /> <a href="#">Tommy Hilfiger<span>(0)</span> </a>
                                            <span class="checkmark"></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="sidebar-widget-list-left">
                                            <input type="checkbox" value="" /> <a href="#">Versace<span>(0)</span> </a>
                                            <span class="checkmark"></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="sidebar-widget mt-30 b-b-0">
                            <h3 class="sidebar-title">SELECT BY COLOR</h3>
                            <div class="sidebar-widget-list-column">
                                <div class="sidebar-widget-list">
                                    <ul>
                                        <li>
                                            <div class="sidebar-widget-list-left">
                                                <input type="checkbox" /> <a href="#">Black<span>(6)</span> </a>
                                                <span class="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Blue<span>(7)</span></a>
                                                <span class="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Brown<span>(4)</span> </a>
                                                <span class="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Green<span>(9)</span> </a>
                                                <span class="checkmark"></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar-widget-list">
                                    <ul>
                                        <li>
                                            <div class="sidebar-widget-list-left">
                                                <input type="checkbox" /> <a href="#">Pink<span>(7)</span> </a>
                                                <span class="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Red<span>(7)</span></a>
                                                <span class="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">White<span>(9)</span> </a>
                                                <span class="checkmark"></span>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="sidebar-widget-list-left">
                                                <input type="checkbox" value="" /> <a href="#">Yellow<span>(10)</span> </a>
                                                <span class="checkmark"></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="banner-area mt-30px">
                        <div class="banner-wrapper">
                            <a href="shop-4-column.html"><img src="assets/images/banner-image/10.jpg" alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default HFrBodySideBar;


