const Objeto = 3
const Style = 4
const StyleLink = 5
const PreProd = 6
const TxLabel = 101
const Span = 102
const P = 103
const Div = 104
const H1 = 105
const Strong = 106
const Img = 107
const H3 = 108
const H5 = 110
const H2 = 111
const H4 = 112
const H6 = 113
const LabelHtml = 109
const A = 114
const Button = 115
const I = 116
const Section = 117
const UL = 118
const LI = 119
const HR = 120
const OwlCarrousel = 121
const OwlCarrImg = 122
const PreBienVenidos01 = 501
const PreCard01  = 502
const PreCard02Img  = 503
const PreCursos = 1001
module.exports = {  Objeto,
    Style,StyleLink,
    PreProd,
    TxLabel,
    Span,
    P,
    Div,
    A,
    Button,
    I,
    Section,
    H1,H2,H3,H4,H5,H6,
    Strong,
    Img,
    LabelHtml,
    UL,
    LI,
    HR,
    OwlCarrousel,
    OwlCarrImg,
    PreBienVenidos01,
    PreCard01,
    PreCard02Img,

    PreCursos,
}