import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";
class H5BasStrong extends H5BaseTodo {

 render (){  
   return (<strong ref={this.objRef} >
               {this.data.regObj.valtext}
               {this.props.children}
              </strong>)
    }
}    
export default  H5BasStrong;