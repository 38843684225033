import React, { Component } from 'react';
import H5BaseTodo from "../H5/H5BaseTodo";
class HPreCard01 extends H5BaseTodo {

    render() {
        //console.log("atr:", this.data)
        //console.log("atrObj:", this.data.regObj)
        return (
            <div id="serv_tarj" className="col-md-4">
                <div className="card text-center">
                    <div className="card-body">
                        <h4 className="card-title">
                            <strong>
                                <i className={"fas  fa-2x "+ this.getValor("icono") } aria-hidden="true"></i>
                            </strong><p dangerouslySetInnerHTML={{ __html: this.getValor("titulo") }}></p>
                        </h4>
                        <p className="card-text" style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: this.getValor("texto") }}>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default HPreCard01;