import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasLabel extends H5BaseTodo {

 render (){  
     //console.log("this.BasLabel:")
     if (this.data.regObj == null) return (<></>)
     if (this.data.regObj.valtext == null) return (<></>)
     //console.log("this.data.regObj:"+this.data.regObj)
     return ( <>{this.data.regObj.valtext}</>)}
}    
export default  H5BasLabel;