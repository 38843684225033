import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasSpan extends H5BaseTodo {
 render (){  
     return (<span ref={this.objRef} >
              {this.data.regObj.valtext}
              {this.props.children}
            </span>)
    }
}    
export default  H5BasSpan;

