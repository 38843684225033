//npm install react-isotope
//npm install styled-components

import React,{Component} from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
//import apiVar from "global/utils/funVarGlobal";
/*
import H5HeadLink  from "./H5HeadLink";
import H5Header  from "./H5Header";
import H5Box001  from "./H5Box001";
import H5Card001  from "./H5Card001";
import H5Team001  from "./H5Team001";
import H5Intro  from "./H5Intro";
import H5IsoTope001  from "./H5IsoTope001";
import H5Img  from "./respa/H5Img";
import H5ImgMarc001  from "./H5ImgMarc001";
import H5ImgCarr001  from "./H5ImgCarr001";
import H5TextIcon  from "./H5TextIcon";
import H5TextChk001  from "./H5TextChk001";
import H5Contadores  from "./H5Contadores";
import H5Footer  from "./H5Footer";
import H5Acordeon  from "./respa/H5Acordeon";
import './H5Section.css'
*/
import H5BasStyle  from "global/components/H5/H5BasStyle";
import H5BasStyleLink  from "global/components/H5/H5BasStyleLink";

import H5Obj  from "global/components/H5/H5ObjType";

import H5BasLabel  from "global/components/H5/H5BasLabel";
import H5BasSpan  from "global/components/H5/H5BasSpan";
import H5BasP  from "global/components/H5/H5BasP";
import H5BasDiv  from "global/components/H5/H5BasDiv";
import H5Section  from "global/components/H5/H5Section";
import H5BasA  from "global/components/H5/H5BasA";
import H5BasI  from "global/components/H5/H5BasI";
import H5BasImg  from "global/components/H5/H5BasImg";
import H5BasH1  from "global/components/H5/H5BasH1";
import H5BasH2  from "global/components/H5/H5BasH2";
import H5BasH3  from "global/components/H5/H5BasH3";
import H5BasH4  from "global/components/H5/H5BasH4";
import H5BasH5  from "global/components/H5/H5BasH5";
import H5BasHR  from "global/components/H5/H5BasHR";
import H5BasStrong  from "global/components/H5/H5BasStrong";
import H5BasButton  from "global/components/H5/H5BasButton";
import H5BasLabelHtml  from "global/components/H5/H5BasLbHtml";
import H5BasUL  from "global/components/H5/H5BasUL";
import H5BasLI  from "global/components/H5/H5BasLI";
import H5BasOwlCarrusel  from "global/components/H5/H5BasOwlCarrusel";
import H5BasOwlCarrImg  from "global/components/H5/H5BasOwlCarrImg";

import HPreBienVenidos01  from "global/components/HPre/HPreBienVenidos_01";
import HPreCard01  from "global/components/HPre/HPreCard_01";
import HPreCard02Img  from "global/components/HPre/HPreCard_02Img";
import HPreCursos  from "global/components/HPre/HPreCursos";

//import Category  from "modEcomerce/ventas/Categorias";

import './HFrRenPage.css';


class HFrRenPage extends Component{
	constructor(props) {
     super(props);
     this.data=props;
     //console.log("HFrRenPage",this.data)
   }
   procesaObj=(regRow)=>{
    let dataHtml =[]
    for (let iPosi=0;iPosi < regRow.length;iPosi++){
        const rowData = regRow[iPosi]
        //console.log("rowData:",rowData)
        let dataHR=null
        //console.log("data",regRow.length,iPosi,rowData)
        switch(rowData.idtype){
          case H5Obj.Objeto    : dataHR= (<>{this.procesaObj(rowData.hijos)}</>)
                                  break;
          case H5Obj.Style     : dataHR=  (<H5BasStyle  key={iPosi}  regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasStyle>)
                                  break;
          case H5Obj.StyleLink : dataHR=  (<H5BasStyleLink  key={iPosi}  regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasStyleLink>)
                                  break;
          case H5Obj.TxLabel   : dataHR=  (<H5BasLabel  key={iPosi}  regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasLabel>)
                                  break;
          case H5Obj.Span      : dataHR=  (<H5BasSpan  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasSpan>)
                                  break;
          case H5Obj.P         : dataHR=  (<H5BasP  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasP>)
                                  break;
          case H5Obj.Section   : dataHR=  (<H5Section  key={iPosi}    regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5Section>)
                                  break;
          case H5Obj.Div       : dataHR=  (<H5BasDiv  key={iPosi}    regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasDiv>)
                                  break;
          case H5Obj.A         : dataHR=  (<H5BasA  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasA>)
                                  break;
          case H5Obj.I         : dataHR=  (<H5BasI  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasI>)
                                  break;
          case H5Obj.H1        : dataHR=  (<H5BasH1  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasH1>)
                                  break;
          case H5Obj.H2        : dataHR=  (<H5BasH2  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasH2>)
                                  break;
          case H5Obj.H3        : dataHR=  (<H5BasH3  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasH3>)
                                  break;
          case H5Obj.H4        : dataHR=  (<H5BasH4  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasH4>)
                                  break;
          case H5Obj.H5        : dataHR=  (<H5BasH5  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasH5>)
                                break;
          case H5Obj.Img       : dataHR=  (<H5BasImg  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasImg>)
                                break;
          case H5Obj.LabelHtml : dataHR=  (<H5BasLabelHtml  key={iPosi}  regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasLabelHtml>)
                                break;
          case H5Obj.Strong    : dataHR=  (<H5BasStrong  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasStrong>)
                                break;
          case H5Obj.Button    : dataHR=  (<H5BasButton  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasButton>)
                                break;
          case H5Obj.UL        : dataHR=  (<H5BasUL  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasUL>)
                                break;
          case H5Obj.LI        : dataHR=  (<H5BasLI  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasLI>)
                                break;
          case H5Obj.HR        : dataHR=  (<H5BasHR  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasHR>)
                                break;
          case H5Obj.OwlCarrousel: dataHR=  (<H5BasOwlCarrusel  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasOwlCarrusel>)
                                break;
          case H5Obj.OwlCarrImg: dataHR=  (<H5BasOwlCarrImg  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</H5BasOwlCarrImg>)
                                break;
          case H5Obj.PreProd: //dataHR=  (<>hola</>)
                            //dataHR=  (<Category dataObj={this.props.dataObj} >{this.procesaObj(rowData.hijos)}</Category>)
                        break;    

          case H5Obj.PreBienVenidos01: dataHR=  (<HPreBienVenidos01  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</HPreBienVenidos01>)
                        break;    
          case H5Obj.PreCard01: dataHR=  (<HPreCard01  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</HPreCard01>)
                        break;   
          case H5Obj.PreCard02Img: dataHR=  (<HPreCard02Img  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</HPreCard02Img>)
                        break;   


          case H5Obj.PreCursos: dataHR=  (<HPreCursos  key={iPosi}   regObj={rowData}>{this.procesaObj(rowData.hijos)}</HPreCursos>)
                        break;   



                        default :
                //console.log("No Esta**********************************:",rowData.idtype,rowData.nombre)
                //dataHR=  (<Category />)
                dataHR=  (<>{this.procesaObj(rowData.hijos)}</>)
                break;
              }
        //dataHR=(<>hiola</>)        
        // Object.assign(dataHtml , dataHR)
        //dataHtml = <>{dataHtml} 11 {dataHR}</>
        //dataHtml = dataHtml + dataHR
        if (dataHR) dataHtml.push(dataHR)
    }
    //console.log(dataHtml)
    return dataHtml
   }


render (){  
     return (<>{this.procesaObj(this.data.page[0].arrObj)}</>)} 
}    
export default  WithNavigate(HFrRenPage);
