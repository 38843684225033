import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasStyle extends H5BaseTodo{
 render (){  
   return (<style ref={this.objRef} media="all" >
                {this.data.regObj.valtext}
              </style>)
    }
}    
export default  H5BasStyle;