import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasDiv extends H5BaseTodo {

   render (){  
     return (<section ref={this.objRef}>
       {this.props.children}
            </section>)
    }
}    
export default  H5BasDiv;