import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import ShowLoading from "global/components/ShowLoading";
import apiRuta from "global/utils/funRuta";
import HFrBarra from "global/components/Page/Header/HFrBarra";
import HFormUser from "global/components/Page/Forms/HFormUser";
import ApiAuth from "global/auth";
import ApiGlo from "global/ApiGlobal";

class HeaderPage_v01 extends HFormUser {
    componentDidMount() { this.readApi() }
    readApi=()=> {
        this.setState({ loading: true });
        this.post(ApiGlo.headerTopNav)
            .then(data => {
                //this.setState({ data: data, loading: false })
                this.setState({ loading: false })
                if (data.dataObj && data.dataObj.regEcoHost && data.dataObj.regEcoHost.titulo != null) {
                    document.title = data.dataObj.regEcoHost.titulo
                    //this.data.interFn(data)
                    apiRuta.setStateData(this, "dataHeader", data)
                }
            })
    }
    componentDidUpdate(nextProps) {
        this.data = nextProps
    }

    render = () => {
        if (this.state.loading) return (<ShowLoading />)
        const data = apiRuta.getStateData(this, "dataHeader")
        if (!data || !data.dataObj || !data.dataObj.regEcoDom)
            return <>No Existe Host declarado</>
        ApiAuth.setEmpresa(data.dataObj.regEcoDom.nro_empresa)
        //console.log("Header 03",data.dataObj)    
        return (<HFrBarra key={'prueba'} dataObj={data.dataObj} />)
    }
}
export default HeaderPage_v01;
