import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";
import apiVar from "global/utils/funVar";
//apiVar.getImgPathWWW()
//<img ref={this.objRef} />
//<img src={this.getValor("src")} />
class H5BasOwlCarrImg extends H5BaseTodo {
    componentDidMount() {
        //const stImg = `${apiVar.getPathImg()}${this.data.regObj.objAtr[iAtr].valtext}`
        //apiVar.setNroEmpresa(1002)
        const stImg = apiVar.getImgPathWWW()
        for (let iAtr=0;iAtr < this.data.regObj.objAtr.length;iAtr++){
          if (this.data.regObj.objAtr[iAtr].nombre==="src")
                this.objRef.current[this.data.regObj.objAtr[iAtr].nombre] = stImg + this.data.regObj.objAtr[iAtr].valtext
          else this.objRef.current[this.data.regObj.objAtr[iAtr].nombre] = this.data.regObj.objAtr[iAtr].valtext
        }
      }      
 render (){  
     return (<div className="portafolio-item">
                <h4>{this.getValor("titulo")}</h4>
                <div className="item">
                    <img ref={this.objRef} />
                </div>
                <p>{this.getValor("descripcion")}</p>
            </div>
            )
    }
}    
export default  H5BasOwlCarrImg;