//https://www.npmjs.com/package/react-owl-carousel
import React,{Component} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import H5BaseTodo from "./H5BaseTodo";
//class H5BasOwl extends H5BaseTodo {
    const options = {
        margin: 78,  // margen tentre item
        responsiveClass: true,
        loop: true,
        center: true,
        //mouseDrag:true,   //touchDrag,pullDrag,freeDrag
        //stagePadding:200,
        next:100,
        autoplay: true,
        //autoplayTimeout:100,
        nav: false,  // Muestre next,prev
        navText: ["Anterior", "Siguiente"], //navElement
        smartSpeed: 1000,  // Velocidad de Movimiento
        responsive: {  // Cuantos Items según px
              0: {items: 1,},
            400: {items: 1,},
            600: {items: 2,},
            700: {items: 5,},
            1000: {items: 5,},
            2000: {items: 5,},
        },
      };
class H5BasOwlCarrusel extends H5BaseTodo {
    render (){  
     //<OwlCarousel className='owl-theme' loop margin={10} nav>
     return (
    <OwlCarousel className="slider-items owl-carousel" {...options}
            ref={this.objRef} 
            >
         {this.props.children}
    </OwlCarousel>)
    }
}    
export default  H5BasOwlCarrusel;