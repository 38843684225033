import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasLI extends H5BaseTodo {

   render (){  
     return (<li ref={this.objRef}>
      {this.data.regObj.valtext}
       {this.props.children}
            </li>)
    }
}    
export default  H5BasLI;