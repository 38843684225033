//Scroll Infinito
//https://www.pluralsight.com/guides/how-to-implement-infinite-scrolling-with-reactjs
//https://www.kuworking.com/javascript-hook-para-infinite-scroll
//https://www.youtube.com/watch?v=VbXAwjAxfU0
//https://www.youtube.com/watch?v=oCHdFiCgOSE
//https://www.youtube.com/watch?v=NZKUirTtxcg

import apiRuta from "global/utils/funRuta";
import React, { Component, lazy } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
//const Login = lazy(()=> import("Pages/Login"));
import Api from "modEcomerce/ApiEcomerce";
import CardProdCategorys from './components/ShoppingCard01';
//import CardProdCategorys from './components/ShoppingCard02';
import ShowLoading from "global/components/ShowLoading";
class CategoriasPadre extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            dataMenu: [],
            loading: true,
        }
    }

    componentDidMount() {
        if (this.props.location) {
            const query = new URLSearchParams(this.props.location.search);
            const refCat = query.get('refCat')
            this.readApi(refCat)
            return
        }
        this.readApi(null)
    }
    readApi = (refCat) => {
        this.post(Api.headerCategoryProd, { 'idKey': refCat })
            .then(data => { this.setState({ dataMenu: data, loading: false });/*console.log(data)*/ })
    }


    addCarr(form,idModelo) {
        //console.log("categoria33",idModelo,form.usuarioVar,form.context)
        form.post(Api.addCarro, { 'idKey': idModelo })
            .then((data) => {
                form.context.dataUser.dataHeader.dataObj.regTotal=data.dataObj.regTotal
                form.context.dataUser.dataHeader.dataObj.regCarro=data.dataObj.regCarro

                //console.log("categoria55",form.context,data)
                apiRuta.setStateGlb(form)
            })
    }
    render = () => {
        //this.usuarioVar = this.context;
        //console.log("render Categoria00",this.usuarioVar)
        if (this.state.loading) return (<ShowLoading />)
        //apiRuta.setStateData(this, "dataHeader2", "data")
        //console.log("rowsMenu", this.state.dataMenu)
        if (!this.state.dataMenu.rows || this.state.dataMenu.rows.length == 0) return (<></>)
        return (<CardProdCategorys
            lProductos={this.state.dataMenu.rows}
            titulo="Productos"
            addCar={this.addCarr}
        />
        )
    }

}
export default CategoriasPadre;