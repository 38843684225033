import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";
class H5BasH3 extends H5BaseTodo {

 render (){  
   return (<h3 ref={this.objRef} >
               {this.data.regObj.valtext}
               {this.props.children}
              </h3>)
    }
}    
export default  H5BasH3;