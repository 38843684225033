import React, { Component } from 'react';
import H5BaseTodo from "../H5/H5BaseTodo";
class HPreBienVenidos_01 extends H5BaseTodo {

    render() {
        //console.log("atr:", this.data)
        //console.log("atrObj:", this.data.regObj)
        return (
            <section ref={this.objRef} id="nosotros">
                <div className="about">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-6 col-sm-12">
                                <div className="col-lg-7 col-md-6 col-sm-12 about-img">
                                    <img src={this.getValor("imagen")} alt="Nosotros" style={{ width: '100%' }} />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 col-sm-12">
                                <div className="section-header text-left">
                                    <h3><strong dangerouslySetInnerHTML={{ __html: this.getValor("titulo") }}></strong></h3>
                                </div>
                                <div  style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{ __html: this.getValor("texto") }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.children}
            </section>
        )
    }
}
export default HPreBienVenidos_01;