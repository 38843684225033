import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasH4 extends H5BaseTodo {

 render (){  
   return (<h4 ref={this.objRef} >
               {this.data.regObj.valtext}
               {this.props.children}
              </h4>)
    }
}    
export default  H5BasH4;