import React from 'react';
import { useRef } from 'react';
import UserProvider from "global/context/user/UserProvider";
import HeaderPage from "./Header/Web/Header_V01";
import MainRouterMod from "modMenu/Menu/MainRouterMod";
//import PreLoader from "./Header/PreLoaderCircle";
//    <PreLoader />
//const Container = ({ history, ...props }) => {
const Container = ({ history }) => {
    let dataClass = {};
    const interHijo = useRef(null);
    const interHeader = useRef(null);
    //console.log("props:", props)
    function interfaceClass(data) {
        console.log("click Container Web*******", data)
        dataClass = data
        //interHijo.current.hijoInter(dataClass)
        //console.log("hijo", hijo)
    }
    function interfaceRead(){
        console.log("hijo interfaceRead")
        //if (interHeader.current)interHeader.current.readApi()
    }
    return (
        <UserProvider>
            <HeaderPage ref={interHeader}  id="harrysHeader" interFn={interfaceClass} />
            <MainRouterMod ref={interHijo} 
                 interFn={interfaceClass} 
                 interRd={interfaceRead} 
                 dataClass={dataClass} />
        </UserProvider>
    )
};
export default Container;
//{regPag.idobj == null && <>No Tiene página asignada</>}
//{regPag.idobj != null && <FrBody dataObj={this.state.data.dataObj} />}
//{this.state.data.dataObj.regPage.length == 0 && <MainRouterMod  />}

// {this.state.data.dataObj.regPage.map((regPag, iCol) => {
//     return (<>
//     </>)
// })}
