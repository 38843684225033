import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasA extends H5BaseTodo{
 
 render (){ 
  //console.log("NN:"+this.data.regObj.nombre + ":" +this.data.regObj.valtext) 
   return (<a ref={this.objRef} >
               {this.data.regObj.valtext}
               {this.props.children}
              </a>)
    }
}    
export default  H5BasA;