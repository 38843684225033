import React,{Component} from 'react';
//import apiVar from "../../../global/utils/funVarGlobal";
//import './H5TextChk001.css'

class H5BaseTodo extends Component{
	constructor(props) {
    super(props);
    this.data=props;
    //console.log(this.data)
    this.objRef = React.createRef();
    //this.focusTextInput = this.focusTextInput.bind(this);
  }  
  // focusTextInput() {
  //   // Hace enfoque explícitamente del campo de texto, haciendo uso de un API del DOM
  //   // Nota: Estamos accediendo la propiedad "current" para obtener el nodo del DOM
  //   this.textInput.current.focus();
  // }
  // returnNombre=()=>{
  //   if (1==1) return ""
  //   return this.data.regObj.nombre + "XX"
  // }
   getValor=(stNombre)=>{
    for (let iAtr=0;iAtr < this.data.regObj.objAtr.length;iAtr++){
       //console.log("comparo",this.data.regObj.objAtr[iAtr].nombre, stNombre,this.data.regObj.objAtr[iAtr].valtext)
       if (this.data.regObj.objAtr[iAtr].nombre == stNombre)
           return this.data.regObj.objAtr[iAtr].valtext
    }
    return null
   }

   componentDidMount() { 
    //console.log("didmoun",this.data.regObj)
     for (let iAtr=0;iAtr < this.data.regObj.objAtr.length;iAtr++){
        //  console.log("iAtr:" + iAtr)
        //  console.log("nombre:" + this.data.regObj.objAtr[iAtr].nombre)
        //  console.log("valtext:" + this.data.regObj.objAtr[iAtr].valtext)
       if (this.data.regObj != null
        && this.data.regObj.objAtr != null
        && this.data.regObj.objAtr.length > 0
        //&& this.data.regObj.objAtr[iAtr] !=null
        && this.data.regObj.objAtr[iAtr].valtext !=null
        && this.data.regObj.objAtr[iAtr].nombre   != null
        && this.data.regObj.objAtr[iAtr].idatr   > 0
        ){
           try{this.objRef.current[this.data.regObj.objAtr[iAtr].nombre] = this.data.regObj.objAtr[iAtr].valtext} 
           catch(ex){/*console.log("Atributo ",this.data.regObj.objAtr[iAtr].nombre)*/}
        }
    }
  }   
}    
export default  H5BaseTodo;