import React, { Component } from 'react';
import H5BaseTodo from "../H5/H5BaseTodo";
class HPreCard02Img extends H5BaseTodo {

    render() {
        console.log("atr:", this.data)
        console.log("atrObj:", this.data.regObj)
        return (<>hola</>
        )
    }
}
export default HPreCard02Img;