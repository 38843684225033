import React, { Component } from 'react';
import H5RenPage from "modEcomerce/ventas/components/HFrRenPage";
import ApiGlo from "global/ApiGlobal";
import FrBodySideBar from "./FrBodySideBar";
import ShowLoading from "global/components/ShowLoading";
import HFormUser from "global/components/Page/Forms/HFormUser";
import WithNavigate from "global/components/Page/Forms/WithNavigate";

class HFrBody extends HFormUser {
    swSideBar = false;
    stPath="";
    constructor(props) {
        super(props);
        this.data = props
        this.state = { loading: true }

//        console.log("FrBody  bodyConstructor****")
    }
    // componentDidMount() {
    //     console.log("FrBody componentDidMount****")
    // }
    readApi=()=> {
        this.setState({ loading: true });
        this.post(ApiGlo.headerTopNav)
            .then(data => {
                //this.setState({ data: data, loading: false })
                this.setState({ loading: false })
                if (data.dataObj && data.dataObj.regEcoHost && data.dataObj.regEcoHost.titulo != null) {
                    document.title = data.dataObj.regEcoHost.titulo
                    //this.data.interFn(data)
                    //apiRuta.setStateData(this, "dataHeader", data)
                    this.data = data
                    this.setState({ loading: false })
                }
            })
    }
    //componentDidUpdate(prevProps) {
        //console.log("FrBody componentDidUpdate****",prevProps)
    //}
    hijoInter = (data) => {
        //console.log("LLegue Page Body",data)
        this.data = data
        this.setState({ loading: false })
    }

    render() {
        if(this.stPath != this.props.location.pathname){
            this.readApi()
            this.setState({ loading: true })
        }
        this.stPath= this.props.location.pathname
        if (this.state.loading)return (<ShowLoading />)
        //console.log("Body render ",this.props.location)
        //console.log("Body render  ****",this.data.interRouter())
        //if (this.state.loading) return (<></>)
        let stClColPrd = "col-lg-12"
        if (this.swSideBar) stClColPrd = "col-lg-9"
        //console.log("Body PageReg",this.data.dataObj);
        //{this.stPath}Hola { new Date().toLocaleTimeString()}
        return (
            <div className="shop-category-area">
                <div className="container">
                    <div className="row">
                        <div className={stClColPrd + " order-lg-last col-md-12 order-md-first"}>
                            {this.data.dataObj
                                && this.data.dataObj.regPage
                                && this.data.dataObj.regPage.length > 0
                                && this.data.dataObj.regPage.map((reg, iCol) => {
                                    //console.log("reg",reg);
                                    return <H5RenPage
                                        key={iCol}
                                        page={this.data.dataObj.regPage}
                                        dataObj={this.data.dataObj}
                                    />
                                })}
                        </div>
                        {this.swSideBar && <FrBodySideBar />}
                    </div>
                </div>
            </div>
        );
    }
}

export default WithNavigate(HFrBody);