import React, { Component } from 'react';
import ApiGlo from "global/ApiGlobal";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
//import apiRuta from "global/utils/funRuta";
//import HFrBody from "../../../../cl-emp-venta-cli/src/modEcomerce/ventas/components/HFrBody";
//import BarraHeader from "global/components/Page/Header/Barra/BarraHeader";
import HrBarraMenu from "./HFrBarraMenu";
import BarraCarro from "global/components/Page/Header/Barra/BrHeadCarroPop";
import BarraCarroMob from "global/components/Page/Header/Barra/BrHeadCarroPopMob";
/*
import BarraStart from "global/components/Page/Header/Barra/BarraNav01Start";
import BarraStartMob from "global/components/Page/Header/Barra/BarraNav01StartMob";

*/

class HFrBarra extends HFormUser {
    pDataBarra = {
        pSearchRoute: ApiGlo.checkOutSearchRoute
        , pHeartRoute: ApiGlo.checkOutHeartRoute
        , pCheckOutDatoCompraFactRoute: ApiGlo.checkOutDatoCompraFactRoute
        , pAddCarro: "/free/producto/addcarrito"
        //, pAddCarro: ApiGlo.addCarro
        , pCheckOutRoute: ApiGlo.checkOutRoute
        , pCategoriaRoute: ApiGlo.categoriaRoute
        , pCheckOutSearchRoute: ApiGlo.checkOutSearchRoute
    }
    /*
       getBarraM1 = (regBar, index) => {
          return (<>
             <BarraStart dataObj={this.data.dataObj}
                key={'hsdwyt_' + index}
                regBar={regBar} />
             <BarraStartMob dataObj={this.data.dataObj}
                key={'hlohjyt_' + index}
                regBar={regBar} />
          </>)
       }

       getBarraM3 = (regBar, index) => {
          return (<>
             <BarraMenVer dataObj={this.data.dataObj}
                key={'hydeet_' + index}
                pDataBarra={this.pDataBarra}
                regBar={regBar}
             />
    
             <BarraMenuHeadMob dataObj={this.data.dataObj}
                key={'hyrfgt_' + index}
                pDataBarra={this.pDataBarra}
                regBar={regBar}
             />
    
             <BarraMenuCateMob dataObj={this.data.dataObj}
                key={'h22yt_' + index}
                pDataBarra={this.pDataBarra}
                regBar={regBar}
             />
    
          </>)
       }
       getBarraM4 = (regBar, index) => {
          return (
             <div className="contact-link contact-linkwa d-lg-none"
                key={'hyt_' + index}
                onClick={() => { window.location.href = "https://wa.me/" + this.data.dataObj.regEcoHost.html_wasap + "?text=Me gustaría%20realizar%20una%20consulta" }}
                style={{ cursor: "pointer" }}>
                <a href={"https://wa.me/" + this.data.dataObj.regEcoHost.html_wasap + "?text=Me gustaría%20realizar%20una%20consulta"}></a>
             </div>)
       }*/
    render = () => {
        return (<>
            {/* Carrito  */}
            <BarraCarro
                dataObj={this.data.dataObj}
                key={'hiioyeyt_' + 'index'}
                pDataBarra={this.pDataBarra}
            />
            <BarraCarroMob
                dataObj={this.data.dataObj}
                key={'hiioyeMob_' + 'index'}
                pDataBarra={this.pDataBarra}
            />
            {this.data.dataObj
                && this.data.dataObj.regHeadBar
                && this.data.dataObj.regHeadBar.map((regBar, index) => {
                        return (<HrBarraMenu key={"Ijt" + index}
                            pDataBarra={this.pDataBarra}
                            dataObj={this.data.dataObj}
                            regBar={regBar}

                        />
                        )
                })}
        </>)
    }
}
// regCatego={this.data.dataObj.regCatego}
// regCarro={this.data.dataObj.regCarro}
// regEcoDom={this.data.dataObj.regEcoDom}
// regEcoHost={this.data.dataObj.regEcoHost}
// regMenHead={this.data.dataObj.regMenHead}
// regTotal={this.data.dataObj.regTotal}
// regPage={this.data.dataObj.regPage}
// cuenta={this.state.cuenta}  
export default WithNavigate(HFrBarra);

