import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";
class H5BasP extends H5BaseTodo {

 render (){  
     return (<p   ref={this.objRef}> 
                {this.data.regObj.valtext}
                {this.props.children}
                </p>)
    }
}    
export default  H5BasP;