import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasButton extends H5BaseTodo {

  render (){  
     return (<button ref={this.objRef} >
              {this.props.children}
            </button>)
    }
}    
export default  H5BasButton;