import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";
import apiVar from "global/utils/funVar";

class H5BasImg extends H5BaseTodo {
   componentDidMount() {
    //const stImg = `${apiVar.getPathImg()}${this.data.regObj.objAtr[iAtr].valtext}`
    //apiVar.setNroEmpresa(1002)
    const stImg = apiVar.getImgPathWWW()
    for (let iAtr=0;iAtr < this.data.regObj.objAtr.length;iAtr++){
      if (this.data.regObj.objAtr[iAtr].nombre==="src")
            this.objRef.current[this.data.regObj.objAtr[iAtr].nombre] = stImg + this.data.regObj.objAtr[iAtr].valtext
      else this.objRef.current[this.data.regObj.objAtr[iAtr].nombre] = this.data.regObj.objAtr[iAtr].valtext
    }
  }  
  render (){  
     return (<img ref={this.objRef} />)
    }
}    
export default  H5BasImg;