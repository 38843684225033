import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasI extends H5BaseTodo {
 
 render (){  
   return (<i ref={this.objRef} >
               {this.data.regObj.valtext}
               {this.props.children}
              </i>)
    }
}    
export default  H5BasI;