import React,{Component} from 'react';
import H5BaseTodo from "./H5BaseTodo";

class H5BasUL extends H5BaseTodo {

   render (){  
     return (<ul ref={this.objRef}>
       {this.data.regObj.valtext}
       {this.props.children}
            </ul>)
    }
}    
export default  H5BasUL;